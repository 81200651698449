
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import Tagify from '@yaireo/tagify';
import {Actions} from "@/store/enums/StoreEnums";
import roomPriceType from "@/core/data/roompricetype";
import roomPricePlatform from "@/core/data/roompriceplatform";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import weekDaysList from "@/core/data/weekdays";
import roomMinAccType from "@/core/data/roomminacctype";
import roomProfitType from "@/core/data/roomprofittype";
import curList from "@/core/data/currency";
import flightType from "@/core/data/flighttypes";
import ticketTypes from "@/core/data/tickettypes";
import genderTypes from "@/core/data/gender";
import paymentMethods from "@/core/data/paymentMethods";
import airlineClassesTypes from "@/core/data/airlineclassestype";

import MasterConfig from "@/core/config/MasterConfig";
import curExchangeType from "@/core/data/curexchangetype";
import {commonf} from "@/core/filters/common";
import {dateConvert} from "@/core/filters/datatime";
import ExcelParse from "@/components/common/ExcelParse.vue";
import {read, utils} from "xlsx";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

interface newReservations {
    resProductID: number;
    resID: string;
    resDepartment: string;
    resAccount: string;
    resMarket: string;
    resADTCount: number,
    resCHDCount: number,
    resINFCount: number,
    resSaleCur: string,

    resCurRateStatus: string;
    resCurRateID: string;
    resCurRate: Array<any>;

    resContactName: string;
    resContactEmail: string;
    resContactPhone: string;

    itemProduct: Array<any>;
    itemProcess: Array<any>;
    itemProcessDate: Array<any>;
    itemTrip: string;
    itemFlightCount: number;

    itemStartDate: Array<any>;
    itemFinishDate: Array<any>;
    itemSupplier: Array<any>;
    itemFrom: Array<any>;
    itemTo: Array<any>;
    itemProductCount: Array<any>;
    itemADTCount: number;
    itemCHDCount: number;
    itemINFCount: number;
    itemCur: Array<any>;
    itemCostCur: Array<any>;
    itemTotalCost: Array<any>;
    itemTotalCurCost: Array<any>;
    itemTotalAccountPrice: Array<any>;
    itemTotalPrice: Array<any>;

    itemRemarksVC1: Array<any>;
    itemRemarksVC2: Array<any>;
    itemRemarksVC3: Array<any>;
    itemRemarksVC4: Array<any>;
    itemRemarksVC5: Array<any>;
    itemRemarksVC6: Array<any>;
    itemRemarksDC1: Array<any>;
    itemRemarksJSON1: Array<any>;
    itemRemarksJSON2: Array<any>;
    itemRemarksJSON3: Array<any>;

    itemStartTime: Array<any>;
    itemFinishTime: Array<any>;

    paxADTGender: Array<any>;
    paxADTName: Array<any>;
    paxADTSurname: Array<any>;
    paxADTBDate: Array<any>;
    paxADTID: Array<any>;
    paxADTTicketNo: Array<any>;
    paxADTReturnTicketNo: Array<any>;

    paxCHDGender: Array<any>;
    paxCHDName: Array<any>;
    paxCHDSurname: Array<any>;
    paxCHDBDate: Array<any>;
    paxCHDID: Array<any>;
    paxCHDTicketNo: Array<any>;
    paxCHDReturnTicketNo: Array<any>;

    paxINFGender: Array<any>;
    paxINFName: Array<any>;
    paxINFSurname: Array<any>;
    paxINFBDate: Array<any>;
    paxINFID: Array<any>;
    paxINFTicketNo: Array<any>;
    paxINFReturnTicketNo: Array<any>;

    paxExcelStatus: boolean;
    paxExcelSelected: string;

    paxPriceADT: Array<any>;
    paxPriceCHD: Array<any>;
    paxPriceINF: Array<any>;

    paxAccountPriceADT: Array<any>;
    paxAccountPriceCHD: Array<any>;
    paxAccountPriceINF: Array<any>;

    paxCostADT: Array<any>;
    paxCostCHD: Array<any>;
    paxCostINF: Array<any>;

    paxTaxADT: Array<any>;
    paxTaxCHD: Array<any>;
    paxTaxINF: Array<any>;

    itemSupplierPaymentType: string;
    itemSupplierPaymentMethod: string;
    itemSupplierPaymentAmount: string;
    itemSupplierPaymentCur: string;


    itemPaymentStatus: boolean;
    itemPaymentType: string;
    itemPaymentMethod: string;
    itemPaymentAmount: string;
    itemPaymentCur: string;

    resCurTRY: string;
    resCurUSD: string;
    resCurEUR: string;
    resCurSTG: string;
}

export default defineComponent({
    name: "new-room-modal",
    components: {
        ErrorMessage,
        Field,
        Form,
        ExcelParse,
    },
    data() {
        return {
            dtpProcessDateDep: null,
            dtpProcessDateArr: null,
            dtpStartDate: null,
            dtpFinishDate: null,
        }
    },
    props: {
        idCode:{type:String, default:'Def'},
        reservationid: {type:Number, default:0},
        isproduct: {type:Boolean, default:false},
        isupdate: {type:Boolean, default:false},
    },
    mounted() {
        this.initDatePickers(null, null, null);
        return true;
    },
    methods: {

        setContent(prmFlightInfo) {
            //vv1a
            console.log("setContent v1 Form "+JSON.stringify(prmFlightInfo));

            //RemarksJSON3: JSON.stringify( {FlightDepTime: newReservations.value.itemStartTime[0], FlightArrTime: newReservations.value.itemFinishTime[0], FlightNo: newReservations.value.itemRemarksVC4[0]}),
            //ProcessDate:dateConvert.method.flatpickerToDateYM(newReservations.value.itemProcessDate[0]),

            let objRemarksJSON1 = JSON.parse(prmFlightInfo.RemarksJSON1);
            let objRemarksJSON3 = JSON.parse(prmFlightInfo.RemarksJSON3);

            this.newReservations.resProductID = Number(prmFlightInfo.ID);
            this.newReservations.resID = prmFlightInfo.ReservationID;

            this.newReservations.itemFrom[0] = prmFlightInfo.From;
            this.newReservations.itemTo[0] = prmFlightInfo.To;

            this.newReservations.itemStartTime[0] = objRemarksJSON3.FlightDepTime;
            this.newReservations.itemFinishTime[0] = objRemarksJSON3.FlightArrTime;

            this.newReservations.itemRemarksVC1[0] = prmFlightInfo.RemarksVC1;
            this.newReservations.itemRemarksVC2[0] = prmFlightInfo.RemarksVC2;
            this.newReservations.itemRemarksVC3[0] = prmFlightInfo.RemarksVC3;
            this.newReservations.itemRemarksVC4[0] = prmFlightInfo.RemarksVC4;
            this.newReservations.itemRemarksVC5[0] = prmFlightInfo.RemarksVC5;
            this.newReservations.itemSupplier[0] = Number(prmFlightInfo.SupplierID);

            if (objRemarksJSON1.PaxType==="ADT") {
                this.newReservations.paxADTGender[0] = objRemarksJSON1.PaxGender;
                this.newReservations.paxADTName[0] = objRemarksJSON1.PaxName;
                this.newReservations.paxADTSurname[0] = objRemarksJSON1.PaxSurname;
                this.newReservations.paxADTBDate[0] = objRemarksJSON1.PaxBirthdate;
                this.newReservations.paxADTID[0] = objRemarksJSON1.PaxID;
                this.newReservations.paxADTTicketNo[0] = objRemarksJSON1.PaxTicketNo;
            }
            if (objRemarksJSON1.PaxType==="CHD") {
                this.newReservations.paxCHDGender[0] = objRemarksJSON1.PaxGender;
                this.newReservations.paxCHDName[0] = objRemarksJSON1.PaxName;
                this.newReservations.paxCHDSurname[0] = objRemarksJSON1.PaxSurname;
                this.newReservations.paxCHDBDate[0] = objRemarksJSON1.PaxBirthdate;
                this.newReservations.paxCHDID[0] = objRemarksJSON1.PaxID;
                this.newReservations.paxCHDTicketNo[0] = objRemarksJSON1.PaxTicketNo;
            }
            if (objRemarksJSON1.PaxType==="INF") {
                this.newReservations.paxINFGender[0] = objRemarksJSON1.PaxGender;
                this.newReservations.paxINFName[0] = objRemarksJSON1.PaxName;
                this.newReservations.paxINFSurname[0] = objRemarksJSON1.PaxSurname;
                this.newReservations.paxINFBDate[0] = objRemarksJSON1.PaxBirthdate;
                this.newReservations.paxINFID[0] = objRemarksJSON1.PaxID;
                this.newReservations.paxINFTicketNo[0] = objRemarksJSON1.PaxTicketNo;
            }

            this.newReservations.itemProcessDate[0] = dateConvert.method.dateToflatpickerFormated(prmFlightInfo.ProcessDate, 'DD-MM-YYYY');
            this.newReservations.itemStartDate[0] = dateConvert.method.dateToflatpickerFormated(prmFlightInfo.StartDate, 'DD-MM-YYYY');
            this.newReservations.itemFinishDate[0] = dateConvert.method.dateToflatpickerFormated(prmFlightInfo.FinishDate, 'DD-MM-YYYY');
            this.initDatePickers(this.newReservations.itemProcessDate[0], this.newReservations.itemStartDate[0], this.newReservations.itemFinishDate[0]);

            setTimeout(this.initAirPorts, 1000)

            console.log("setContent v1 newReservations: "+JSON.stringify(this.newReservations));

        },

        initAirPorts() {
            libTools.method.setElValue("txtAirPorts_ResFlightForm_Dep_From_"+this.idCode, this.newReservations.itemFrom[0]);
            libTools.method.setElValue("txtAirPorts_ResFlightForm_Dep_To_"+this.idCode, this.newReservations.itemTo[0]);

            console.log("initAirPorts END "+this.newReservations.itemFrom[0]);
        },

        initDatePickers(prmProcessDate, prmStartDate, prmFinishDate) {

            let cnfProcess = {
                "locale": Turkish,
                dateFormat: "d-m-Y",
                //defaultDate: prmProcessDate!==null? dateConvert.method.dateToflatpicker(prmProcessDate): "",
                defaultDate: prmProcessDate!==null? prmProcessDate: "",
            };

            let cnfStart = {
                "locale": Turkish,
                dateFormat: "d-m-Y",
                defaultDate: prmStartDate!==null? prmStartDate: "",
            };

            let cnfFinish = {
                "locale": Turkish,
                dateFormat: "d-m-Y",
                defaultDate: prmFinishDate!==null? prmFinishDate: "",
            };

            console.log("initDatePickers id: "+"#txtDate_ResFlightForm_Dep_ProcessDate_"+this.idCode+" prmProcessDate: "+prmProcessDate);

            this.dtpProcessDateDep = flatpickr("#txtDate_ResFlightForm_Dep_ProcessDate_"+this.idCode, cnfProcess);
            this.dtpProcessDateArr = flatpickr("#txtDate_ResFlightForm_Arr_ProcessDate_"+this.idCode, cnfProcess);

            this.dtpStartDate = flatpickr("#txtDate_ResFlightForm_Dep_StartDate_"+this.idCode, cnfStart);
            this.dtpFinishDate = flatpickr("#txtDate_ResFlightForm_Dep_FinishDate_"+this.idCode, cnfFinish);
        },

    },
    setup(props) {
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);
        const newExcelParseRef = ref<null | HTMLButtonElement>(null);

        const roomSale = ref(null)
        const roomActive = ref(null)


        const newReservations = ref<newReservations>({
            resProductID: 0,
            resID: "",
            resDepartment: "",
            resAccount: "",
            resMarket: "",
            resADTCount: 1,
            resCHDCount: 0,
            resINFCount: 0,
            resSaleCur: "",

            resCurRateStatus: "AUTO",
            resCurRateID: "",
            resCurRate: [],

            resContactName: "",
            resContactEmail: "",
            resContactPhone: "",

            itemProduct: [],
            itemProcess: [],
            itemTrip: "ONAWAY",
            itemProcessDate: [],
            itemFlightCount: 1,

            itemStartDate: [],
            itemFinishDate: [],
            itemSupplier: [],
            itemFrom: [],
            itemTo: [],
            itemProductCount: [],
            itemADTCount: 1,
            itemCHDCount: 0,
            itemINFCount: 0,
            itemCur: [],
            itemCostCur: [],
            itemTotalCost: [],
            itemTotalCurCost: [],
            itemTotalAccountPrice: [],
            itemTotalPrice: [],

            itemRemarksVC1: [],
            itemRemarksVC2: [],
            itemRemarksVC3: [],
            itemRemarksVC4: [],
            itemRemarksVC5: [],
            itemRemarksVC6: [],
            itemRemarksDC1: [],
            itemRemarksJSON1: [],
            itemRemarksJSON2: [],
            itemRemarksJSON3: [],

            itemStartTime: [],
            itemFinishTime: [],

            paxADTGender: [],
            paxADTName: [],
            paxADTSurname: [],
            paxADTBDate: [],
            paxADTID: [],
            paxADTTicketNo: [],
            paxADTReturnTicketNo: [],

            paxCHDGender: [],
            paxCHDName: [],
            paxCHDSurname: [],
            paxCHDBDate: [],
            paxCHDID: [],
            paxCHDTicketNo: [],
            paxCHDReturnTicketNo: [],

            paxExcelStatus: false,
            paxExcelSelected: "false",

            paxPriceADT: [],
            paxPriceCHD: [],
            paxPriceINF: [],

            paxAccountPriceADT: [],
            paxAccountPriceCHD: [],
            paxAccountPriceINF: [],

            paxCostADT: [],
            paxCostCHD: [],
            paxCostINF: [],

            paxTaxADT: [],
            paxTaxCHD: [],
            paxTaxINF: [],

            paxINFGender: [],
            paxINFName: [],
            paxINFSurname: [],
            paxINFBDate: [],
            paxINFID: [],
            paxINFTicketNo: [],
            paxINFReturnTicketNo: [],

            itemSupplierPaymentType: "",
            itemSupplierPaymentMethod: "",
            itemSupplierPaymentAmount: "",
            itemSupplierPaymentCur: "",

            itemPaymentStatus: true,
            itemPaymentType: "",
            itemPaymentMethod: "",
            itemPaymentAmount: "",
            itemPaymentCur: "",

            resCurTRY: "",
            resCurUSD: "",
            resCurEUR: "",
            resCurSTG: "",

        });


        const payload4 = {
            Type: 'AIRLINE',
        }
        store.dispatch(Actions.SUPPLIERS_LIST_ALL, payload4);
        const mySupplierList = computed(() => {
            return store.getters.suppliersList;
        });

        const payloadCRL = {
            Type: 'AUTO',
            validDate: dateConvert.method.getTodayYMD(),
            is_active: 'active',
            limit: 1,
        }

        store.dispatch(Actions.CUR_RATE_LIST, payloadCRL);
        const myCur = computed(() => {
            let tmpData = store.getters.curRateList;
            //if (tmpData.value !== undefined) newReservations.value.resCurTRY = "1";
            if (tmpData.data !== undefined) {
                return tmpData.data[0];
            } else {
                return {
                    CurTRY: 1,
                    CurUSD: 0,
                    CurEUR: 0,
                    CurSTG: 0,
                }
            }

        });

        store.dispatch(Actions.AIRLINE_LIST, {});
        const myAirlineList = computed(() => {
            return store.getters.airlineList;
        });

        store.dispatch(Actions.AIRLINE_CLASSES_LIST_ALL, {});
        const myAirlineClassesList = computed(() => {
            return store.getters.airlineClassesList;
        });

        store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
        const myDepartments = computed(() => {
            return store.getters.DepartmentsList;
        });

        store.dispatch(Actions.BANKACCOUNTS_LIST, {});
        const myBankAccount = computed(() => {
            return store.getters.bankAccountsList;
        });

        store.dispatch(Actions.BANKPOS_LIST, {});
        const myBankPos = computed(() => {
            return store.getters.bankPosList;
        });

        store.dispatch(Actions.COMPANY_CREDIT_CARD_LIST, {});
        const myCreditCard = computed(() => {
            return store.getters.companyCreditCardList;
        });

        store.dispatch(Actions.AIRLINE_CLASSES_LIST_ALL, {});
        const myAirlineClasses = computed(() => {
            return store.getters.airlineClassesList;
        });

        const myAirportList = computed(() => {
            let dataComputed = store.getters.airportsList;
            console.log("myAirportList dataComputed: "+JSON.stringify(dataComputed));
            return dataComputed;
        });
        const myAirportsTitle = computed(() => {

            console.log("myAirportsTitle myAirportList: "+JSON.stringify(myAirportList));

            let tmpArray = [];
            let fruits: string[] = [];
            if (myAirportList.value.data !== undefined) {
                for (var i of myAirportList.value.data) {
                    tmpArray.push("(" + i.IATACode + ") - " + i.Title + " - " + i.City);
                }
            }
            return tmpArray;
        });
        const getAirports = (ID) => {
            let myFrom = document.getElementById(ID) as HTMLInputElement;
            let myPayload = {};
            if (myFrom.value.length == 3) {
                myPayload = {
                    'IATACode': myFrom.value.toUpperCase()
                }
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            } else if (myFrom.value.length == 4) {
                myPayload = {
                    'Title': myFrom.value
                }
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            }
        }

        const myExcelTitle = computed(() => {
            let myTempTitle = store.getters.excelParseTitleResult;
            let cntStatus = false;
            if (myTempTitle.length > 0) {
                for (let i = 0; i < myTempTitle.length; i++) {
                    if (myTempTitle[i] !== undefined) {
                        if (i == 0) {
                            cntStatus = true
                        }
                    }
                }
                if (cntStatus) {
                    setPaxList();
                }
            }
            return myTempTitle;
        });

        const myTotalFlightPrice = computed(() => {
            let myTCur = newReservations.value.resSaleCur;

            let myCostCur = newReservations.value.itemCostCur[0];
            let myCurRate = 0;
            let myCostCurRate = 0;
            if (myTCur == 'TRY') myCurRate = myCur.value.CurTRY;
            if (myTCur == 'USD') myCurRate = myCur.value.CurUSD;
            if (myTCur == 'EUR') myCurRate = myCur.value.CurEUR;
            if (myTCur == 'STG') myCurRate = myCur.value.CurSTG;

            if (myCostCur == 'TRY') myCostCurRate = myCur.value.CurTRY;
            if (myCostCur == 'USD') myCostCurRate = myCur.value.CurUSD;
            if (myCostCur == 'EUR') myCostCurRate = myCur.value.CurEUR;
            if (myCostCur == 'STG') myCostCurRate = myCur.value.CurSTG;
            let tmpCostResCur = {
                CUR: "",
                TOTALCOST: 0,
                ADT: 0,
                CHD: 0,
                INF: 0,
            };
            if (myTCur == myCostCur) {
                tmpCostResCur = {
                    CUR: myTCur,
                    TOTALCOST: (newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount) + (newReservations.value.paxCostCHD[0] * newReservations.value.itemCHDCount) + (newReservations.value.paxCostINF[0] * newReservations.value.itemINFCount),
                    ADT: Number((newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount)),
                    CHD: Number((newReservations.value.paxCostCHD[0] * newReservations.value.itemCHDCount)),
                    INF: Number((newReservations.value.paxCostINF[0] * newReservations.value.itemINFCount)),
                }
            } else {
                let tmpADTPrice = ((newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount) * myCostCurRate) / myCurRate;
                let tmpCHDPrice = ((newReservations.value.paxCostCHD[0] * newReservations.value.itemCHDCount) * myCostCurRate) / myCurRate;
                let tmpINFPrice = ((newReservations.value.paxCostINF[0] * newReservations.value.itemINFCount) * myCostCurRate) / myCurRate;

                tmpCostResCur = {
                    CUR: myCostCur,
                    TOTALCOST: Number((tmpADTPrice + tmpCHDPrice + tmpINFPrice).toFixed(2)),
                    ADT: Number((tmpADTPrice).toFixed(2)),
                    CHD: Number((tmpCHDPrice).toFixed(2)),
                    INF: Number((tmpINFPrice).toFixed(2)),
                }
            }

            /*******RETURN FLIGHT*******/
            let myCostCurReturn = newReservations.value.itemCostCur[1];
            let myCostCurRateReturn = 0;

            if (myCostCurReturn == 'TRY') myCostCurRateReturn = myCur.value.CurTRY;
            if (myCostCurReturn == 'USD') myCostCurRateReturn = myCur.value.CurUSD;
            if (myCostCurReturn == 'EUR') myCostCurRateReturn = myCur.value.CurEUR;
            if (myCostCurReturn == 'STG') myCostCurRateReturn = myCur.value.CurSTG;
            let tmpCostResCurReturn = {
                CUR: "",
                TOTALCOST: 0,
                ADT: 0,
                CHD: 0,
                INF: 0,
            };
            if (myTCur == myCostCurReturn) {
                tmpCostResCurReturn = {
                    CUR: myTCur,
                    TOTALCOST: (newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount) + (newReservations.value.paxCostCHD[1] * newReservations.value.itemCHDCount) + (newReservations.value.paxCostINF[1] * newReservations.value.itemINFCount),
                    ADT: Number((newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount)),
                    CHD: Number((newReservations.value.paxCostCHD[1] * newReservations.value.itemCHDCount)),
                    INF: Number((newReservations.value.paxCostINF[1] * newReservations.value.itemINFCount)),
                }
            } else {
                let tmpADTPrice = ((newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount) * myCostCurRateReturn) / myCurRate;
                let tmpCHDPrice = ((newReservations.value.paxCostCHD[1] * newReservations.value.itemCHDCount) * myCostCurRateReturn) / myCurRate;
                let tmpINFPrice = ((newReservations.value.paxCostINF[1] * newReservations.value.itemINFCount) * myCostCurRateReturn) / myCurRate;

                tmpCostResCurReturn = {
                    CUR: myCostCur,
                    TOTALCOST: Number((tmpADTPrice + tmpCHDPrice + tmpINFPrice).toFixed(2)),
                    ADT: Number((tmpADTPrice).toFixed(2)),
                    CHD: Number((tmpCHDPrice).toFixed(2)),
                    INF: Number((tmpINFPrice).toFixed(2)),
                }
            }


            let myTempPrices = {
                CUR: newReservations.value.resSaleCur,
                COSTCUR: newReservations.value.itemCostCur,
                ITEMS: [
                    {
                        /*******DEP FLIGHT*********/
                        PRICE: {
                            ADT: (newReservations.value.paxPriceADT[0] * newReservations.value.itemADTCount).toFixed(2),
                            CHD: (newReservations.value.paxPriceCHD[0] * newReservations.value.itemCHDCount).toFixed(2),
                            INF: (newReservations.value.paxPriceINF[0] * newReservations.value.itemINFCount).toFixed(2),
                        },
                        COST: {
                            ADT: (newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount).toFixed(2),
                            CHD: (newReservations.value.paxCostCHD[0] * newReservations.value.itemCHDCount).toFixed(2),
                            INF: (newReservations.value.paxCostINF[0] * newReservations.value.itemINFCount).toFixed(2),
                        },
                        TAX: {
                            ADT: (newReservations.value.paxTaxADT[0] * newReservations.value.itemADTCount).toFixed(2),
                            CHD: (newReservations.value.paxTaxCHD[0] * newReservations.value.itemCHDCount).toFixed(2),
                            INF: (newReservations.value.paxTaxINF[0] * newReservations.value.itemINFCount).toFixed(2),
                        },
                        TOTAL: {
                            PRICE: ((newReservations.value.paxPriceADT[0] * newReservations.value.itemADTCount) + ((newReservations.value.paxPriceCHD[0] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxPriceINF[0] || 0) * newReservations.value.itemINFCount)).toFixed(2),
                            COST: ((newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount) + ((newReservations.value.paxCostCHD[0] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxCostINF[0] || 0) * newReservations.value.itemINFCount)).toFixed(2),
                            TAX: ((newReservations.value.paxTaxADT[0] * newReservations.value.itemADTCount) + ((newReservations.value.paxTaxCHD[0] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxTaxINF[0] || 0) * newReservations.value.itemINFCount)).toFixed(2),
                            PROFIT: Number((newReservations.value.paxPriceADT[0] * newReservations.value.itemADTCount) + ((newReservations.value.paxPriceCHD[0] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxPriceINF[0] || 0) * newReservations.value.itemINFCount)) - Number(tmpCostResCur.TOTALCOST.toFixed(2)),
                        },
                    },
                    {
                        /*******ARR FLIGHT*********/
                        PRICE: {
                            ADT: (newReservations.value.paxPriceADT[1] * newReservations.value.itemADTCount).toFixed(2),
                            CHD: (newReservations.value.paxPriceCHD[1] * newReservations.value.itemCHDCount).toFixed(2),
                            INF: (newReservations.value.paxPriceINF[1] * newReservations.value.itemINFCount).toFixed(2),
                        },
                        COST: {
                            ADT: (newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount).toFixed(2),
                            CHD: (newReservations.value.paxCostCHD[1] * newReservations.value.itemCHDCount).toFixed(2),
                            INF: (newReservations.value.paxCostINF[1] * newReservations.value.itemINFCount).toFixed(2),
                        },
                        TAX: {
                            ADT: (newReservations.value.paxTaxADT[1] * newReservations.value.itemADTCount).toFixed(2),
                            CHD: (newReservations.value.paxTaxCHD[1] * newReservations.value.itemCHDCount).toFixed(2),
                            INF: (newReservations.value.paxTaxINF[1] * newReservations.value.itemINFCount).toFixed(2),
                        },
                        TOTAL: {
                            PRICE: ((newReservations.value.paxPriceADT[1] * newReservations.value.itemADTCount) + ((newReservations.value.paxPriceCHD[1] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxPriceINF[1] || 0) * newReservations.value.itemINFCount)).toFixed(2),
                            COST: ((newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount) + ((newReservations.value.paxCostCHD[1] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxCostINF[1] || 0) * newReservations.value.itemINFCount)).toFixed(2),
                            TAX: ((newReservations.value.paxTaxADT[1] * newReservations.value.itemADTCount) + ((newReservations.value.paxTaxCHD[1] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxTaxINF[1] || 0) * newReservations.value.itemINFCount)).toFixed(2),
                            PROFIT: Number((newReservations.value.paxPriceADT[1] * newReservations.value.itemADTCount) + ((newReservations.value.paxPriceCHD[1] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxPriceINF[1] || 0) * newReservations.value.itemINFCount)) - Number(tmpCostResCurReturn.TOTALCOST.toFixed(2)),
                        },
                    },
                ],
                TOTALPRICE: {
                    PRICE: (((newReservations.value.paxPriceADT[0]+(newReservations.value.paxPriceADT[1] || 0)) * newReservations.value.itemADTCount) +
                        (((newReservations.value.paxPriceCHD[0] || 0)+(newReservations.value.paxPriceCHD[1] || 0)) * newReservations.value.itemCHDCount) +
                        (((newReservations.value.paxPriceINF[0] || 0)+(newReservations.value.paxPriceINF[1] || 0)) * newReservations.value.itemINFCount)).toFixed(2)
                },
                COST_SALE_CUR: {
                    CUR: myCostCur,
                    TOTALCOST: (tmpCostResCur.ADT || 0) + (tmpCostResCur.CHD || 0) + (tmpCostResCur.INF || 0) + (tmpCostResCurReturn.ADT || 0) + (tmpCostResCurReturn.CHD || 0) + (tmpCostResCurReturn.INF || 0),
                    ADT: Number(tmpCostResCur.ADT).toFixed(2),
                    CHD: Number(tmpCostResCur.CHD + tmpCostResCurReturn.CHD).toFixed(2),
                    INF: Number(tmpCostResCur.INF + tmpCostResCurReturn.INF).toFixed(2),
                },
                COST_SALE_CUR_DEPERTURE: tmpCostResCur,
                COST_SALE_CUR_ARRIVAL: tmpCostResCurReturn,
            };
            //console.log(myTempPrices);
            return myTempPrices;
        });

        const setPaxList = () => {
            console.log(myExcelTitle);
            newReservations.value.itemADTCount = 0;
            newReservations.value.itemCHDCount = 0;
            newReservations.value.itemINFCount = 0;
            for (let ic = 1; ic < excelData.length; ic++) {
                if (excelData[ic][myExcelTitle.value[0]] == 'ADT') {
                    if (myExcelTitle.value[1]) newReservations.value.paxADTGender[newReservations.value.itemADTCount] = excelData[ic][myExcelTitle.value[1]]
                    if (myExcelTitle.value[2]) newReservations.value.paxADTName[newReservations.value.itemADTCount] = excelData[ic][myExcelTitle.value[2]]
                    if (myExcelTitle.value[3]) newReservations.value.paxADTSurname[newReservations.value.itemADTCount] = excelData[ic][myExcelTitle.value[3]]
                    if (myExcelTitle.value[4]) newReservations.value.paxADTBDate[newReservations.value.itemADTCount] = excelData[ic][myExcelTitle.value[4]]
                    if (myExcelTitle.value[5]) newReservations.value.paxADTID[newReservations.value.itemADTCount] = excelData[ic][myExcelTitle.value[5]]
                    if (myExcelTitle.value[6]) newReservations.value.paxADTTicketNo[newReservations.value.itemADTCount] = excelData[ic][myExcelTitle.value[6]]
                    if (myExcelTitle.value[7]) newReservations.value.paxADTReturnTicketNo[newReservations.value.itemADTCount] = excelData[ic][myExcelTitle.value[7]]
                    newReservations.value.itemADTCount++;
                } else if (excelData[ic][myExcelTitle.value[0]] == 'CHD') {
                    if (myExcelTitle.value[1]) newReservations.value.paxCHDGender[newReservations.value.itemCHDCount] = excelData[ic][myExcelTitle.value[1]]
                    if (myExcelTitle.value[2]) newReservations.value.paxCHDName[newReservations.value.itemCHDCount] = excelData[ic][myExcelTitle.value[2]]
                    if (myExcelTitle.value[3]) newReservations.value.paxCHDSurname[newReservations.value.itemCHDCount] = excelData[ic][myExcelTitle.value[3]]
                    if (myExcelTitle.value[4]) newReservations.value.paxCHDBDate[newReservations.value.itemCHDCount] = excelData[ic][myExcelTitle.value[4]]
                    if (myExcelTitle.value[5]) newReservations.value.paxCHDID[newReservations.value.itemCHDCount] = excelData[ic][myExcelTitle.value[5]]
                    if (myExcelTitle.value[6]) newReservations.value.paxCHDTicketNo[newReservations.value.itemCHDCount] = excelData[ic][myExcelTitle.value[6]]
                    if (myExcelTitle.value[7]) newReservations.value.paxCHDReturnTicketNo[newReservations.value.itemCHDCount] = excelData[ic][myExcelTitle.value[7]]
                    newReservations.value.itemCHDCount++;
                } else if (excelData[ic][myExcelTitle.value[0]] == 'INF') {
                    if (myExcelTitle.value[1]) newReservations.value.paxINFGender[newReservations.value.itemINFCount] = excelData[ic][myExcelTitle.value[1]]
                    if (myExcelTitle.value[2]) newReservations.value.paxINFName[newReservations.value.itemINFCount] = excelData[ic][myExcelTitle.value[2]]
                    if (myExcelTitle.value[3]) newReservations.value.paxINFSurname[newReservations.value.itemINFCount] = excelData[ic][myExcelTitle.value[3]]
                    if (myExcelTitle.value[4]) newReservations.value.paxINFBDate[newReservations.value.itemINFCount] = excelData[ic][myExcelTitle.value[4]]
                    if (myExcelTitle.value[5]) newReservations.value.paxINFID[newReservations.value.itemINFCount] = excelData[ic][myExcelTitle.value[5]]
                    if (myExcelTitle.value[6]) newReservations.value.paxINFTicketNo[newReservations.value.itemINFCount] = excelData[ic][myExcelTitle.value[6]]
                    if (myExcelTitle.value[7]) newReservations.value.paxINFReturnTicketNo[newReservations.value.itemINFCount] = excelData[ic][myExcelTitle.value[7]]
                    newReservations.value.itemINFCount++;
                }
                //if(list[i]=='')
            }
        }

        const validationSchema = Yup.object().shape({
            roomTitle: Yup.string().required().label("Oda İsmi"),
            roomCategory: Yup.string().required().label("Oda Kategory"),
            roomADT: Yup.string().required().label("Yetişkin Kapasite"),
        });
        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }
            //Disable button
            // submitButtonRef.value.disabled = true;
            // Activate indicator
            // submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            //let isActive = (roomActive.value.checked) ? 'active' : 'passive';
            //let isSale = (roomSale.value.checked) ? 'open' : 'stop';


            let payloadFlights = {};
            let payloadPaxADT = {};
            let payloadPaxCHD = {};
            let payloadPaxINF = {};

            for (let ipa = 0; ipa < newReservations.value.itemADTCount; ipa++) {
                payloadPaxADT[ipa] = {
                    PaxType: 'ADT',
                    PaxGender: newReservations.value.paxADTGender[ipa],
                    PaxName: newReservations.value.paxADTName[ipa],
                    PaxSurname: newReservations.value.paxADTSurname[ipa],
                    PaxBirthdate: newReservations.value.paxADTBDate[ipa],
                    PaxID: newReservations.value.paxADTID[ipa],
                    PaxTicketNo: newReservations.value.paxADTTicketNo[ipa],
                    PaxReturnTicketNo: newReservations.value.paxADTReturnTicketNo[ipa],
                };
            }

            for (let ipc = 0; ipc < newReservations.value.itemCHDCount; ipc++) {
                payloadPaxCHD[ipc] = {
                    PaxType: 'CHD',
                    PaxGender: newReservations.value.paxCHDGender[ipc],
                    PaxName: newReservations.value.paxCHDName[ipc],
                    PaxSurname: newReservations.value.paxCHDSurname[ipc],
                    PaxBirthdate: newReservations.value.paxCHDBDate[ipc],
                    PaxID: newReservations.value.paxCHDID[ipc],
                    PaxTicketNo: newReservations.value.paxCHDTicketNo[ipc],
                    PaxReturnTicketNo: newReservations.value.paxCHDReturnTicketNo[ipc],
                };
            }

            for (let ipi = 0; ipi < newReservations.value.itemINFCount; ipi++) {
                payloadPaxINF[ipi] = {
                    PaxType: 'INF',
                    PaxGender: newReservations.value.paxINFGender[ipi],
                    PaxName: newReservations.value.paxINFName[ipi],
                    PaxSurname: newReservations.value.paxINFSurname[ipi],
                    PaxBirthdate: newReservations.value.paxINFBDate[ipi],
                    PaxID: newReservations.value.paxINFID[ipi],
                    PaxTicketNo: newReservations.value.paxINFTicketNo[ipi],
                    PaxReturnTicketNo: newReservations.value.paxINFReturnTicketNo[ipi],
                };
            }

            for (let i = 0; i < newReservations.value.itemFlightCount; i++) {
                let myFlightFrom;
                let myFlightTo;

                if (i===0) {
                    myFlightFrom = document.getElementById("txtAirPorts_ResFlightForm_Dep_From_" + props.idCode) as HTMLInputElement;
                    myFlightTo = document.getElementById("txtAirPorts_ResFlightForm_Dep_To_" + props.idCode) as HTMLInputElement;

                } else {
                    myFlightFrom = document.getElementById("flightFrom" + i) as HTMLInputElement;
                    myFlightTo = document.getElementById("flightTo" + i) as HTMLInputElement;
                }

                let matchesFrom = myFlightFrom.value.match(/\(([^)]+)\)/);
                let matchesTo = myFlightTo.value.match(/\(([^)]+)\)/);
                //console.log(matchesTo);
                //console.log(matchesFrom);
                if (matchesFrom) {
                    newReservations.value.itemFrom[i] = matchesFrom[1];
                }
                if (matchesTo) {
                    newReservations.value.itemTo[i] = matchesTo[1];
                }
                payloadFlights[i] = {
                    ReservationID : props.reservationid,
                    ProductType: 'FLIGHT',
                    ProcessType: 'RESERVATION',
                    ProcessDate: dateConvert.method.flatpickerToDateYM(newReservations.value.itemProcessDate[i]),
                    StartDate: dateConvert.method.convertToDateYM(newReservations.value.itemStartDate[i]),
                    FinishDate: dateConvert.method.convertToDateYM(newReservations.value.itemFinishDate[i]),
                    SupplierID: newReservations.value.itemSupplier[i],
                    From: newReservations.value.itemFrom[i],
                    To: newReservations.value.itemTo[i],
                    Campaign: '{}',
                    ProductCount: newReservations.value.itemADTCount+newReservations.value.itemCHDCount,
                    ADTCount: newReservations.value.itemADTCount,
                    CHDCount: newReservations.value.itemCHDCount,
                    INFCount: newReservations.value.itemINFCount,
                    Cur: newReservations.value.resSaleCur,
                    CostCur: newReservations.value.itemCostCur[i],
                    TotalCost: myTotalFlightPrice.value.ITEMS[i].TOTAL.COST,
                    TotalAccountPrice: myTotalFlightPrice.value.ITEMS[i].TOTAL.PRICE,
                    TotalPrice: myTotalFlightPrice.value.ITEMS[i].TOTAL.PRICE,
                    TotalCurCost: (i==0) ? myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.TOTALCOST : myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.TOTALCOST,
                    RemarksVC1: newReservations.value.itemRemarksVC1[i],
                    RemarksVC2: newReservations.value.itemRemarksVC2[i],
                    RemarksVC3: newReservations.value.itemRemarksVC3[i],
                    RemarksVC4: newReservations.value.itemRemarksVC4[i],
                    RemarksVC5: newReservations.value.itemRemarksVC5[i],
                    RemarksVC6: newReservations.value.itemRemarksVC6[i],
                    RemarksJSON1: {
                        ADT: payloadPaxADT,
                        CHD: payloadPaxCHD,
                        INF: payloadPaxINF,
                    },
                    RemarksJSON2: {
                        Price: {
                            ADT: newReservations.value.paxPriceADT[i],
                            CHD: (newReservations.value.itemCHDCount >= 1) ? newReservations.value.paxPriceCHD[i] : 0,
                            INF: (newReservations.value.itemINFCount >= 1) ? newReservations.value.paxPriceINF[i] : 0,
                            CUR: newReservations.value.resSaleCur,
                        },
                        AccountPrice: {
                            ADT: newReservations.value.paxPriceADT[i],
                            CHD: (newReservations.value.itemCHDCount >= 1) ? newReservations.value.paxPriceCHD[i] : 0,
                            INF: (newReservations.value.itemINFCount >= 1) ? newReservations.value.paxPriceINF[i] : 0,
                            CUR: newReservations.value.resSaleCur,
                        },
                        Cost: {
                            ADT: newReservations.value.paxCostADT[i],
                            CHD: (newReservations.value.itemCHDCount >= 1) ? newReservations.value.paxCostCHD[i] : 0,
                            INF: (newReservations.value.itemINFCount >= 1) ? newReservations.value.paxCostINF[i] : 0,
                            CUR: newReservations.value.itemCostCur[i],
                            ADTCURCOST: (i==0) ? (myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.ADT/newReservations.value.itemADTCount) : (myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.ADT/newReservations.value.itemADTCount),
                            CHDCURCOST: (i==0) ? (myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.CHD/newReservations.value.itemCHDCount) : (myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.CHD/newReservations.value.itemCHDCount),
                            INFCURCOST: (i==0) ? (myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.INF/newReservations.value.itemINFCount) : (myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.INF/newReservations.value.itemINFCount),
                        },
                        Tax: {
                            ADT: newReservations.value.paxTaxADT[i],
                            CHD: (newReservations.value.itemCHDCount >= 1) ? newReservations.value.paxTaxCHD[i] : 0,
                            INF: (newReservations.value.itemINFCount >= 1) ? newReservations.value.paxTaxINF[i] : 0,
                            CUR: newReservations.value.itemCostCur[i],
                        },
                    },
                    RemarksJSON3: {
                        FlightDepTime: newReservations.value.itemStartTime[i],
                        FlightArrTime: newReservations.value.itemFinishTime[i],
                    },
                };
            }

            let payloadContact = {
                Name: newReservations.value.resContactName,
                Email: newReservations.value.resContactEmail,
                Phone: newReservations.value.resContactPhone,
            };
            let payloadPayment = {
                Method: newReservations.value.itemPaymentType,
                MethodID: newReservations.value.itemPaymentMethod,
                Amount: newReservations.value.itemPaymentAmount,
                Cur: newReservations.value.itemPaymentCur,
            };
            let payloadSupplierPayment = {
                Method: newReservations.value.itemSupplierPaymentType,
                MethodID: newReservations.value.itemSupplierPaymentMethod,
                Amount: newReservations.value.itemSupplierPaymentAmount,
                Cur: newReservations.value.itemSupplierPaymentCur,
            };
            let payloadCur = {
                TRY: myCur.value.CurTRY,
                USD: myCur.value.CurUSD,
                EUR: myCur.value.CurEUR,
                STG: myCur.value.CurSTG,
            };

            function getAccId(myAccount) {
                if (myAccount!==undefined && myAccount.value!==undefined) {

                    let fltV = filterAccounts(myAccount.value);

                    if (fltV!==undefined && fltV[0]!==undefined) {
                        return fltV[0].ID;
                    }
                }

                return 0;
            }

            let myAccount = document.getElementById("addAccounts") as HTMLInputElement;

            const payload1 = {
                ReservationID: props.reservationid,
                Department: newReservations.value.resDepartment,
                Market: newReservations.value.resMarket,
                //AccountID: filterAccounts(myAccount.value)[0].ID!==undefined? filterAccounts(myAccount.value)[0].ID : "0",
                AccountID: getAccId(myAccount),
                AccountVoucherNo: '',
                productFlightCount: (returnFlightStatus.value) ? 1 : 2,
                productData: JSON.stringify(payloadFlights),
                SaleCur: newReservations.value.resSaleCur,
                Contact: JSON.stringify(payloadContact),
                Payment: JSON.stringify(payloadPayment),
                SupplierPayment: JSON.stringify(payloadSupplierPayment),
                CurRate: JSON.stringify(payloadCur),
            }

            console.log(payload1);

            //vv1b

            if (props.isproduct) {

                if (props.isupdate) {

                    //console.log( payloadFlights[0]["ADT"][0]);
                    console.log(payloadFlights[0].RemarksJSON1["ADT"][0]);

                    let payloadUpdate= {
                        ProductType: "FLIGHT",
                        ID:newReservations.value.resProductID,
                        RemarksVC1: newReservations.value.itemRemarksVC1[0],
                        RemarksVC2: newReservations.value.itemRemarksVC2[0],
                        RemarksVC3: newReservations.value.itemRemarksVC3[0],
                        RemarksVC4: newReservations.value.itemRemarksVC4[0],
                        RemarksVC5: newReservations.value.itemRemarksVC5[0],
                        From: newReservations.value.itemFrom[0],
                        To: newReservations.value.itemTo[0],
                        ProcessDate:dateConvert.method.flatpickerToDateYM(newReservations.value.itemProcessDate[0]),
                        StartDate:dateConvert.method.flatpickerToDateYM(newReservations.value.itemStartDate[0]),
                        FinishDate: dateConvert.method.flatpickerToDateYM(newReservations.value.itemFinishDate[0]),
                        RemarksJSON1: JSON.stringify(payloadFlights[0].RemarksJSON1["ADT"][0]),
                        RemarksJSON3: JSON.stringify( {FlightDepTime: newReservations.value.itemStartTime[0], FlightArrTime: newReservations.value.itemFinishTime[0], FlightNo: newReservations.value.itemRemarksVC4[0]}),

                    };

                    console.log("UPDATE payloadUpdate: "+JSON.stringify(payloadUpdate));

                   // store.dispatch(Actions.RESERVATION_PRODUCTS_EDIT, payloadUpdate).then(() => {
                    store.dispatch(Actions.RESERVATIONS_PRODUCT_UPDATE, payloadUpdate).then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.", icon: "success", buttonsStyling: false, confirmButtonText: "Geri Dön!", customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                        }).then(function () {
                            location.reload();
                        });
                    }).catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0], icon: "error", buttonsStyling: false, confirmButtonText: "Tekrar dene!", customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                        });
                    });

                } else {

                   // store.dispatch(Actions.RESERVATION_PRODUCTS_INSERT, payload1).then(() => {
                    store.dispatch(Actions.RESERVATIONS_PRODUCT_INSERT, payload1).then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.", icon: "success", buttonsStyling: false, confirmButtonText: "Geri Dön!", customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                        }).then(function () {
                            location.reload();
                        });
                    }).catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0], icon: "error", buttonsStyling: false, confirmButtonText: "Tekrar dene!", customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                        });
                    });

                }


            } else {

                store.dispatch(Actions.RESERVATIONS_INSERT, payload1).then(() => {
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.", icon: "success", buttonsStyling: false, confirmButtonText: "Geri Dön!", customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                    }).then(function () {
                        location.reload();
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getUserErrors[0], icon: "error", buttonsStyling: false, confirmButtonText: "Tekrar dene!", customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                    });
                });

            }


        };

        const filtClassesDepp = computed(() => {
            if (myAirlineClasses.value.data != null) {
                return (myAirlineClasses.value.data).filter(classItems =>
                    (classItems.Airline == newReservations.value.itemRemarksVC2[0]) &&
                    (classItems.Type == newReservations.value.itemRemarksVC5[0])
                );
            } else {
                return true;
            }
        });

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });


        const setMarket = (e) => {
            newReservations.value.resSaleCur = e.target.selectedOptions[0].dataset.cur;
            newReservations.value.itemCostCur[0] = e.target.selectedOptions[0].dataset.cur;
            newReservations.value.itemCostCur[1] = e.target.selectedOptions[0].dataset.cur;
        }

        let returnFlightStatus = ref(false)
        const setTrip = () => {
            if (newReservations.value.itemTrip == 'ONEWAY') {
                returnFlightStatus.value = false;
                newReservations.value.itemFlightCount = 1;
            } else {
                returnFlightStatus.value = true;
                newReservations.value.itemFlightCount = 2;
            }
        }

        const myAccountList = computed(() => {
            return store.getters.accountList;
        });
        const myAccountsTitle = computed(() => {
            let tmpArray = [];
            if (myAccountList.value.data !== undefined) {
                for (var i of myAccountList.value.data) {
                    tmpArray.push(i.Title);
                }
            }
            return tmpArray;
        });

        const getAccounts = () => {
            let myAccounts = document.getElementById("addAccounts") as HTMLInputElement;
            let myPayload = {};
            console.log(myAccounts);
            if (myAccounts.value.length == 3) {
                myPayload = {
                    'Title': myAccounts.value
                }
                store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
            }
        }
        const filterAccounts = (val) => {
            if (myAccountList.value.data != null) {
                return (myAccountList.value.data).filter(Items =>
                    (Items.Title == val)
                );
            } else {
                return true;
            }
        };

        function onExcelStatus() {
            if (newReservations.value.paxExcelStatus == false) {
                excelData = [];
                newReservations.value.itemADTCount = 1;
                newReservations.value.itemCHDCount = 0;
                newReservations.value.itemINFCount = 0;
            }
        }

        let excelData = [];
        const onExcelChange = (event) => {
            newReservations.value.paxExcelSelected = "false";
            let file = event.target.files ? event.target.files[0] : null;
            if (file) {
                const reader = new FileReader();
                newReservations.value.paxExcelSelected = "true";
                reader.onload = (e) => {
                    /* Parse data */
                    const bstr = e.target.result;
                    const wb = read(bstr, {type: 'binary'});
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = utils.sheet_to_json(ws, {header: 1});
                    excelData = data;
                    store.dispatch(Actions.EXCEL_PARSE_TITLE, data[0]);
                    newExcelParseRef.value.click();
                }

                reader.readAsBinaryString(file);
            }
        }

        const addEventToNumber = () => {
            var priceInput = document.querySelectorAll(".priceInput");
            priceInput.forEach(function (element) {
                console.log(element);
                element.addEventListener("keyup", commonf.inputDecimal, false);
            });
            return true;
        }
        const testFunc = () => {
            addEventToNumber();
            console.log("yhkhgjk");
            return true;
        };


        return {
            testFunc,
            addEventToNumber,
            commonf,
            myDepartments,
            mySupplierList,
            myAirlineList,
            myAirlineClassesList,
            myAirportsTitle,
            myAccountList,
            myAccountsTitle,
            getAirports,
            getAccounts,
            myExcelTitle,
            myBankAccount,
            myBankPos,
            myCreditCard,
            myCur,
            myTotalFlightPrice,
            filtClassesDepp,
            roomPriceType,
            roomPricePlatform,
            weekDaysList,
            roomMinAccType,
            roomProfitType,
            curList,
            flightType,
            ticketTypes,
            genderTypes,
            paymentMethods,
            airlineClassesTypes,
            curExchangeType,
            onExcelChange,
            onExcelStatus,
            myMarkets,
            roomActive,
            roomSale,
            returnFlightStatus,
            validationSchema,
            submit,
            submitButtonRef,
            newRoomModalRef,
            newReservations,
            newExcelParseRef,
            setMarket,
            setTrip,
            MasterConfig,
        };
    },
});
