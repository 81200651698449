
import {computed, defineComponent, onMounted, onUpdated, ref, watch} from "vue";

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"

import PaxDetail from "@/components/general-modals/cards/parts/PaxDetail.vue";
import DayDetail from "@/components/general-modals/cards/parts/DayDetail.vue";
import {dateConvert} from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

interface IUpdateRoomdetail {
  SecretProfit : string;
  RemarksINT4 : string;
  BankCommissionPercentPrice : string;
  SubAgencyPercentPrice : string;
  AgencyPercentPrice : string;

  AgencyPercent : string;
  SubAgencyPercent : string;
  BankCommissionPercent : string;

}


export default defineComponent({
    name: "Room-Detail",
    components: {
        PaxDetail,
        DayDetail,
    },
    methods: {
        initContent(prmReservation) {
           // console.log("RoomDetail initContent START");
            this.initContentInner(prmReservation);
        },
        setContent(prmHotelInfo) {
           // console.log("RoomDetail setContent "+JSON.stringify(prmHotelInfo));
            this.setContentInner(prmHotelInfo);
        },
        getResults() {
            let rv = {isok : true, room: {roomdetail: {}, paxes:[], days:[], daytotals: {CustomerPrice:0, Cost:0, AgencyPrice:0,}} };
            //console.log("RoomDetail getResults roomid: "+this.roomid+" ccvPaxCountADT: "+this.ccvPaxCountADT+" ccvPaxCountCHD: "+this.ccvPaxCountCHD+" ccvPaxCountINF: "+this.ccvPaxCountINF+" len: "+this.refComp_PaxDetails.length);
            console.log("RoomDetail getResults roomid: "+this.roomid+" pax.len: "+this.refComp_PaxDetails.length+" day.len: "+this.refComp_DayDetails.length);
            if (this.refComp_PaxDetails.length!=undefined && this.refComp_DayDetails.length!==undefined) {

                //Check Paxes
                if (rv.isok) {
                    for (let i=0; i<this.ccvPaxCountADT + this.ccvPaxCountCHD + this.ccvPaxCountINF; i++) {
                        console.log("RoomDetail getResults each Pax "+i);
                        if (rv.isok) {
                            let resPax = (this.refComp_PaxDetails[i] as any).getResults();
                            if (resPax.isok) {rv.room.paxes.push(resPax.result);}
                            else {rv.isok = false;}
                        }
                    }
                }

                //Check Days
                if (rv.isok) {
                    for (let i = 0; i < this.ccvDatesInterval; i++) {
                        //console.log("RoomDetail getResults each Day " + i);
                        if (rv.isok) {
                            let resDay = (this.refComp_DayDetails[i] as any).getResults();
                            if (resDay.isok) {
                                rv.room.days.push(resDay.result);

                                //console.log("RoomDetail STG_B getResults each Day "+i+" CustomerPrice: " + resDay.result.CustomerPrice);

                                if (resDay.result.CustomerPrice!==undefined) {
                                    (rv.room.daytotals as any).CustomerPrice+= Number(resDay.result.CustomerPrice);
                                    (rv.room.daytotals as any).Cost+= Number(resDay.result.Cost);
                                    (rv.room.daytotals as any).AgencyPrice+=Number(resDay.result.AgencyPrice);
                                }

                            }
                            else {rv.isok = false;}
                        }
                    }

                    console.log("rv.room.daytotals: "+JSON.stringify(rv.room.daytotals));
                }

                //RoomDetails
                if (rv.isok) {
                    let resRoomDetail = this.confirmValues();
                    if (resRoomDetail.isok) {
                        rv.room.roomdetail = resRoomDetail.result;
                        (rv.room.roomdetail as any).PaxCountADT = this.ccvPaxCountADT;
                        (rv.room.roomdetail as any).PaxCountCHD = this.ccvPaxCountCHD;
                    } else {
                        rv.isok = false;
                    }
                }

                console.log("RoomDetail getResults "+JSON.stringify(rv));
            }
            return rv;
        }
    },
    props: {
        idCode: {type:String, required: true},
        roomid: {type:Number, required:true},
        ProcessType: {String, required: true},
    },
    setup(props, { emit }) {

      const IUpdateRoomdetail = ref<IUpdateRoomdetail>({
        SecretProfit: "",
        RemarksINT4: "",
        BankCommissionPercentPrice: "",
        SubAgencyPercentPrice: "",
        AgencyPercentPrice: "",
        AgencyPercent : "",
        SubAgencyPercent : "",
        BankCommissionPercent : "",

      });


        let refComp_PaxDetails = ref([]);
        let refComp_DayDetails = ref([]);

        let theReservation = ref({});
        let ccvReservationProductId = ref(0);

       // var ccvPaxCountADT = ref(1);
        var ccvPaxCountADT = ref(1);
        var ccvPaxCountCHD = ref(0);
        var ccvPaxCountINF = ref(0);

        var ccvDatesInterval = ref(0);

        let fpickStartDate;
        let fpickFinishDate;

        let ccvHotelInfo = ref({});

       /* let ccvHotelID = ref(23);  //Add yapılan OtelID
        let ccvRoomCode = ref(6);
        let ccvConceptCode = ref(2);*/
        let ccvHotelID = ref(0);  //Add yapılan OtelID
        let ccvRoomCode = ref(0);
        let ccvConceptCode = ref(0);

        let isInitUpdate = false;


        onMounted(()=>{
            initDatePickers(null, null);
        });

        onUpdated(()=>{
            if (isInitUpdate){
                isInitUpdate = false;

                let arrPaxes = JSON.parse((ccvHotelInfo.value as any).RemarksJSON1);
                for (let i=0; i<ccvPaxCountADT.value + ccvPaxCountCHD.value + ccvPaxCountINF.value; i++) {
                    (refComp_PaxDetails.value[i] as any).setContent(arrPaxes[i]);
                }

                let arrDays = JSON.parse((ccvHotelInfo.value as any).RemarksJSON2);
                for(let i=0; i<ccvDatesInterval.value; i++) {
                    (refComp_DayDetails.value[i] as any).setContent(arrDays[i]);
                }
               // console.log("onUpdated ccvDatesInterval : " + ccvDatesInterval.value);
               // console.log("onUpdated arrDays" + arrDays);
            }

        });

        const addRef_PaxDetails = el => {
            if (el) {
                refComp_PaxDetails.value.push(el);
                //console.log("addRef_PaxDetails roomid: "+(el as any).roomid+" "+(el as any).paxType+" paxindex: "+(el as any).paxindex+" pax.len: "+(refComp_PaxDetails.value as any).length);
            }
        }

        const addRef_DayDetails = el => {
            if (el) {
                refComp_DayDetails.value.push(el);
                //console.log("addRef_DayDetails roomid: "+(el as any).roomid+" dayindex: "+(el as any).dayindex+" day.len: "+(refComp_DayDetails.value as any).length);
            }
        }


        function initDatePickers(prmStartDate, prmFinishDate) {
            let cnfStartDate = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                //minDate: "today",
                //defaultDate: prmStartDate!==null ?   prmStartDate : "today",
                defaultDate: prmStartDate!==null ? dateConvert.method.dateToflatpicker(prmStartDate) : "",
            };

            fpickStartDate = flatpickr("#txtStartDate_RoomDetail_"+props.idCode+"_"+props.roomid, cnfStartDate);
            (fpickStartDate as any).config.onChange.push(changeFlatPicker_StartDate);

           // initFinishDatePicker(calcFinishDate(new Date(prmStartDate)), prmFinishDate, "initDatePickers");
            //initFinishDatePicker(calcFinishDate(prmStartDate), prmFinishDate, "initDatePickers");

            if (prmStartDate!==null) {
              initFinishDatePicker(calcFinishDate(new Date(prmStartDate)), prmFinishDate, "initDatePickers");
              changeFlatPicker_StartDate();
            }else {
              initFinishDatePicker(calcFinishDate(new Date()), prmFinishDate, "initDatePickers");
            }

        }

        function initFinishDatePicker(prmMinDate, prmDefaultDate, prmCalledBy) {
          //  console.log("initFinishDatePicker START prmMinDate: "+prmMinDate + " " +prmDefaultDate + " "  +prmCalledBy);
            let cnfFinishDate = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                minDate: dateConvert.method.dateToflatpicker(prmMinDate),
                defaultDate: prmDefaultDate!==null ? dateConvert.method.dateToflatpicker(prmDefaultDate) : "",
            };
            fpickFinishDate = flatpickr("#txtFinishDate_RoomDetail_"+props.idCode+"_"+props.roomid, cnfFinishDate);
            ccvDatesInterval.value = getDatesInterval();

            (fpickFinishDate as any).config.onChange.push(changeFlatPicker_FinishDate);
        }

        function changeFlatPicker_StartDate() {
            //dtValStart.value = dateConvert.method.dateToflatpicker((fpickStartDate as any).selectedDates[0]);
            refComp_DayDetails.value = [];
            //emit_OnDatesIntervalChanged(ccvDatesInterval.value);

           // console.log("changeFlatPicker_StartDate (fpickFinishDate as any).selectedDates[0]: "+(fpickFinishDate as any).selectedDates[0]);

            let dtFinish = calcFinishDate((fpickStartDate as any).selectedDates[0]);


            if ((fpickFinishDate as any).selectedDates[0]!==null && (fpickFinishDate as any).selectedDates[0]!==undefined) {

              if ((fpickFinishDate as any).selectedDates[0]> (fpickStartDate as any).selectedDates[0]) {

                dtFinish = (fpickFinishDate as any).selectedDates[0];
              }

            }

            initFinishDatePicker(calcFinishDate((fpickStartDate as any).selectedDates[0]), dtFinish, "changeFlatPicker_StartDate" );

             ccvDatesInterval.value = getDatesInterval();
           //  console.log("datePicker StartDate Changed ccvDatesInterval: "+ccvDatesInterval.value);
        }

        function changeFlatPicker_FinishDate() {
            refComp_DayDetails.value = [];
            ccvDatesInterval.value = getDatesInterval();
            //emit_OnDatesIntervalChanged(ccvDatesInterval.value);
           // console.log("datePicker FinishtDate Changed ccvDatesInterval: "+ccvDatesInterval.value);
        }

        function getDatesInterval() {
            let rv = 0;

            if ((fpickStartDate as any).selectedDates[0]!==undefined && (fpickFinishDate as any).selectedDates[0]!==undefined) {
                let vvDiffSec = (fpickFinishDate as any).selectedDates[0].getTime() - (fpickStartDate as any).selectedDates[0].getTime();
                let vvDiffDay = vvDiffSec  / (1000 * 3600 * 24);
               // console.log("getDatesInterval roomDetails " + (fpickStartDate as any).selectedDates[0] + " " + (fpickFinishDate as any).selectedDates[0]);
                rv= vvDiffDay;
            }
           // console.log("getDatesInterval hotelupdate " + rv)
            return rv;
        }

        function calcFinishDate(prmDate) {
            let rv = new Date (prmDate);
            rv.setDate(rv.getDate() + 1);
            return rv;
        }

        function emit_OnDatesIntervalChanged(prmValue) {
           // console.log("emit_OnDatesIntervalChanged roomid: "+props.roomid+" prmValue: "+prmValue);
            emit("on-datesinterval-changed", props.roomid, prmValue);
        }

        function emit_OnPaxCountsChanged() {
            //console.log("emit_OnPaxCountsChanged roomid: "+props.roomid+" ccvPaxCountADT: "+ccvPaxCountADT.value);
            emit("on-paxcounts-changed", props.roomid, ccvPaxCountADT.value, ccvPaxCountCHD.value, ccvPaxCountINF.value);
        }

        function changeSelectADT($event) {
          //  console.log("changeSelectADT value: "+$event.target.value+" len: "+refComp_PaxDetails.value.length);
            setPaxADT($event.target.value);
            emit_OnPaxCountsChanged();
        }

        function changeSelectCHD($event) {
            refComp_PaxDetails.value = [];
           // console.log("changeSelectCHD value: "+$event.target.value+" len: "+refComp_PaxDetails.value.length);
            ccvPaxCountCHD.value = Number($event.target.value);
            emit_OnPaxCountsChanged();
        }

        function setPaxADT(prmPax) {
            refComp_PaxDetails.value = [];
            ccvPaxCountADT.value = Number(prmPax);
        }

        function setPaxCHD(prmPax) {
            refComp_PaxDetails.value = [];
            ccvPaxCountCHD.value = Number(prmPax);
        }

        function initContentInner(prmReservation) {
            //console.log("RoomDetail initContentInner START");
            theReservation.value = prmReservation.value;
            //console.log("RoomDetail initContent v1 "+JSON.stringify(theReservation.value));
        }

        function setContentInner(prmHotelInfo) {
            ccvHotelInfo.value = prmHotelInfo;
            ccvReservationProductId.value = prmHotelInfo.ID;
            const mySecretProfit = (document.getElementById("txtSecretProfit_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement);
            const myRemarksINT4 = (document.getElementById("txtRemarksINT4_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement);
            const myAgencyPercentPrice = (document.getElementById("txtAgencyPercentPrice_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement);
            const mySubAgencyPercentPrice = (document.getElementById("txtSubAgencyPercentPrice_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement);
            const myBankCommissionPercentPrice = (document.getElementById("txtBankCommissionPercentPrice_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement);
            const myAgencyPercent = (document.getElementById("txtAgencyPercent_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement);
            const mySubAgencyPercent = (document.getElementById("txtSubAgencyPercent_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement);
            const myBankCommissionPercent = (document.getElementById("txtBankCommissionPercent_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement);
            mySecretProfit.value=prmHotelInfo.SecretProfit;
            myRemarksINT4.value=prmHotelInfo.RemarksINT4;
            myAgencyPercentPrice.value=prmHotelInfo.AgencyPercentPrice;
            mySubAgencyPercentPrice.value=prmHotelInfo.SubAgencyPercentPrice;
            myBankCommissionPercentPrice.value=prmHotelInfo.BankCommissionPercentPrice;
            myAgencyPercent.value=prmHotelInfo.AgencyPercent;
            mySubAgencyPercent.value=prmHotelInfo.SubAgencyPercent;
            myBankCommissionPercent.value=prmHotelInfo.BankCommissionPercent;
         // console.log("setContentInner prmHotelInfo.FinishDate " +prmHotelInfo.FinishDate);
            this.initDatePickers(prmHotelInfo.StartDate, prmHotelInfo.FinishDate);


            if (prmHotelInfo.ADTCount!==ccvPaxCountADT.value) {
                setPaxADT(prmHotelInfo.ADTCount);
            }

            if (prmHotelInfo.CHDCount!==ccvPaxCountCHD.value) {
                setPaxCHD(prmHotelInfo.CHDCount);
            }
            isInitUpdate = true;
        }

        function confirmValues() {
            let rv = {isok : true, result:{},};

            if (rv.isok && (fpickStartDate as any).selectedDates[0]===undefined) {
                libTools.method.swalError("Tamam", "Giriş tarihi seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && (fpickFinishDate as any).selectedDates[0]===undefined) {
                libTools.method.swalError("Tamam", "Çıkış tarihi seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok) {
                rv.result = {
                    RoomId: props.roomid,
                    ReservationProductId: ccvReservationProductId.value,
                    SecretProfit: (document.getElementById("txtSecretProfit_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement).value,
                    RemarksINT4: (document.getElementById("txtRemarksINT4_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement).value,
                    BankCommissionPercentPrice: (document.getElementById("txtBankCommissionPercentPrice_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement).value,
                    SubAgencyPercentPrice: (document.getElementById("txtSubAgencyPercentPrice_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement).value,
                    AgencyPercentPrice: (document.getElementById("txtAgencyPercentPrice_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement).value,
                    AgencyPercent: (document.getElementById("txtAgencyPercent_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement).value,
                  SubAgencyPercent: (document.getElementById("txtSubAgencyPercent_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement).value,
                  BankCommissionPercent: (document.getElementById("txtBankCommissionPercent_RoomDetail_"+props.idCode+'_'+props.roomid) as HTMLInputElement).value,
                    StartDate:dateConvert.method.convertToDateFormated((fpickStartDate as any).selectedDates[0], "YYYY-MM-DD"),
                    FinishDate:dateConvert.method.convertToDateFormated((fpickFinishDate as any).selectedDates[0], "YYYY-MM-DD"),
                } ;
            }

            return rv;
        }

        const dataFlightReservationList = computed(() => {
            const dataComputed = store.getters.getFlightReservationList;
            setDaysPrices(dataComputed.data);
            return dataComputed;
        });

        function setDaysPrices(prmData) {
            if (prmData!==undefined) {
              //  console.log("setDaysPrices "+JSON.stringify(prmData.result.HotelPrice));
                for (let iH=0; iH<prmData.result.HotelPrice.length; iH++) {
                    let tmpEachHotel = prmData.result.HotelPrice[iH];
                    if (tmpEachHotel.HotelCode == ccvHotelID.value) {
                      //  console.log("setDaysPrices tmpEachHotel.RoomPrice.Price: "+JSON.stringify(tmpEachHotel.RoomPrice));
                        for (let iRP=0; iRP<tmpEachHotel.RoomPrice.length; iRP++) {
                            let tmpEachRoomPrice = tmpEachHotel.RoomPrice[iRP];
                         //   console.log("setDaysPrices tmpEachRoomPrice RoomCode tmpEachRoomPrice:"+tmpEachRoomPrice.RoomCode+" ccvRoomCode: "+ccvRoomCode.value+" || ConceptCode tmpEachRoomPrice: "+tmpEachRoomPrice.ConceptCode+" ccvConceptCode: "+ccvConceptCode.value);
                            if (tmpEachRoomPrice.RoomCode===""+ccvRoomCode.value && tmpEachRoomPrice.ConceptCode===""+ccvConceptCode.value) {
                              //  console.log("setDaysPrices FOUNDED tmpEachRoomPrice: "+JSON.stringify(tmpEachRoomPrice));
                                for (let i=0; i<tmpEachRoomPrice.Price.DayPrice.length; i++) {
                                    let tmpEachDayPrice = tmpEachRoomPrice.Price.DayPrice[i];
                                 //   console.log("setDaysPrices tmpEachDayPrice: "+JSON.stringify(tmpEachDayPrice));
                                    let vvDay = {
                                        Price: tmpEachDayPrice.PosterPrice,
                                        Cost: tmpEachDayPrice.Cost,
                                        AgencyPrice: tmpEachDayPrice.AccountPrice,
                                        CustomerPrice: Number(tmpEachDayPrice.AccountPrice)+ Number(tmpEachDayPrice.CommissionAmount),
                                        //CustomerPrice: tmpEachDayPrice.CustomerPrice,

                                    };
                                    if (refComp_DayDetails.value[i]!==undefined && refComp_DayDetails.value[i]!==null) {
                                        (refComp_DayDetails.value[i] as any).setContent(vvDay);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


        function click_GetPrices() {
          //  console.log("click_GetPrices START" );

            let vvHotelId = libTools.method.getElValue("sel_HotelReservation_"+props.ProcessType+"_HotelId");
            let vvHotelRoomId = libTools.method.getElValue("sel_HotelReservation_"+props.ProcessType+"_HotelRoomId");
            let vvHotelConceptId = libTools.method.getElValue("sel_HotelReservation_"+props.ProcessType+"_HotelConceptId");

          //  console.log("click_GetPrices vvHotelId: "+vvHotelId+" vvHotelRoomId: "+vvHotelRoomId+" vvHotelConceptId: "+vvHotelConceptId );
            if (vvHotelId!=="" && vvHotelRoomId!=="" && vvHotelConceptId!=="") {
              //  console.log("click_GetPrices DONE1");
                ccvHotelID.value = Number(vvHotelId);
                ccvRoomCode.value = Number(vvHotelRoomId);
                ccvConceptCode.value = Number(vvHotelConceptId);

                let arrAdults = [];
                let arrChilds = [];
                let arrChildBirthDates = [];

                let isOk = true;

                for (let i=0; i<this.ccvPaxCountADT + this.ccvPaxCountCHD + this.ccvPaxCountINF; i++) {
                    //console.log("RoomDetail getResults each Pax "+i);
                    let resPax = (this.refComp_PaxDetails[i] as any).getResults();
                    //console.log("RoomDetail getResults each Pax "+i+" "+JSON.stringify(resPax));
                    if (resPax.isok) {
                        if (resPax.result.Type==="ADT") {
                            arrAdults.push(resPax.result);
                        }
                        if (resPax.result.Type==="CHD") {
                            arrChilds.push(resPax.result);
                            arrChildBirthDates.push(resPax.result.BirthDate);
                        }
                    }else {
                        isOk = false;
                    }
                }

              //  console.log("click_GetPrices arrAdults: "+ JSON.stringify(arrAdults) );
             //   console.log("click_GetPrices arrChilds: "+ JSON.stringify(arrChilds) );

                if (isOk) {
                    let vvPayload = {
                        "Type": "HOTEL",
                        "SearchType": "HOTEL",
                        "AccountCode": (theReservation.value as any).AccountID,
                        "From": "0",
                        "To": ""+ccvHotelID.value,
                        "StartDate": dateConvert.method.convertToDateFormated((fpickStartDate as any).selectedDates[0], "YYYY-MM-DD"),
                        "FinishDate": dateConvert.method.convertToDateFormated((fpickFinishDate as any).selectedDates[0], "YYYY-MM-DD"),
                        "Pax": {
                            "ADT": arrAdults.length,
                            "CHD": arrChilds.length,
                            "CHDBirthDates": arrChildBirthDates,
                        },
                        "TransferIncluded": "0",
                        "Market": (theReservation.value as any).Market,
                        "Type2": 'HOTEL',
                        "SearchProcessID": "1",
                    };
                //    console.log("click_GetPrices v1 vvPayload: "+JSON.stringify(vvPayload));
                    store.dispatch(Actions.FLIGHT_RESERVATION_LIST, vvPayload);
                }

            }

        }

        return {
            addRef_PaxDetails, refComp_PaxDetails,
            addRef_DayDetails, refComp_DayDetails,
            initDatePickers,
            ccvReservationProductId,
            ccvPaxCountADT, ccvPaxCountCHD, ccvPaxCountINF,
            setPaxADT, setPaxCHD,
            ccvDatesInterval,
            changeSelectADT, changeSelectCHD,
            initContentInner, setContentInner,
            confirmValues,
            click_GetPrices,
            dataFlightReservationList,
            theReservation,
          IUpdateRoomdetail,
          ccvHotelInfo,
        };
    },
});
