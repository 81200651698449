
import {defineComponent, ref} from "vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "Day-Detail",
    methods: {
        setContent(prmDayInfo) {
            console.log("DayDetail setContent "+JSON.stringify(prmDayInfo));
            this.setContentInner(prmDayInfo);
        },
        getResults() {
            console.log("DayDetail getResults roomid: "+this.roomid+" dayindex: "+this.dayindex);
            //let rv = this.confirmValues();
            let rv=this.confirmValues();
            return rv;
        }
    },
    props: {
        idCode : {type:String, required: true},
        roomid: {type:Number, required: true},
        dayindex : {type:Number, required: true},
    },
    setup(props) {

        function setContentInner(prmDayInfo) {
            //console.log("DayDetail setContentInner "+JSON.stringify(prmDayInfo));
            libTools.method.setElValue("txt_RoomDetail_DayDetail_Price_"+props.idCode+"_"+props.roomid+"_"+props.dayindex, Number(prmDayInfo.CustomerPrice) );
            //libTools.method.setElValue("txt_RoomDetail_DayDetail_Price_"+props.idCode+"_"+props.roomid+"_"+props.dayindex, prmDayInfo.CustomerPrice );
            libTools.method.setElValue("txt_RoomDetail_DayDetail_Cost_"+props.idCode+"_"+props.roomid+"_"+props.dayindex, prmDayInfo.Cost);
            libTools.method.setElValue("txt_RoomDetail_DayDetail_AgencyPrice_"+props.idCode+"_"+props.roomid+"_"+props.dayindex, prmDayInfo.AgencyPrice);
        }

        function confirmValues() {
            let rv = {isok: true, result: {},};

            if (rv.isok && libTools.method.getElValue("txt_RoomDetail_DayDetail_Price_"+props.idCode+"_"+props.roomid+"_"+props.dayindex)==="") {
                libTools.method.swalError("Tamam", props.roomid + ". Odadaki "+props.dayindex+". Gün için Fiyat girilmemiş");
                rv.isok = false;
            }
            if (rv.isok && libTools.method.getElValue("txt_RoomDetail_DayDetail_Cost_"+props.idCode+"_"+props.roomid+"_"+props.dayindex)==="") {
                libTools.method.swalError("Tamam", props.roomid + ". Odadaki "+props.dayindex+". Gün için Maliyet girilmemiş");
                rv.isok = false;
            }
            if (rv.isok && libTools.method.getElValue("txt_RoomDetail_DayDetail_AgencyPrice_"+props.idCode+"_"+props.roomid+"_"+props.dayindex)==="") {
                libTools.method.swalError("Tamam", props.roomid + ". Odadaki "+props.dayindex+". Gün için Acente Fiyatı girilmemiş");
                rv.isok = false;
            }

            if (rv.isok) {
                rv.result = {
                    dayindex: props.dayindex,
                    CustomerPrice: libTools.method.getElValue("txt_RoomDetail_DayDetail_Price_"+props.idCode+"_"+props.roomid+"_"+props.dayindex),
                    Cost: libTools.method.getElValue("txt_RoomDetail_DayDetail_Cost_"+props.idCode+"_"+props.roomid+"_"+props.dayindex),
                    AgencyPrice: libTools.method.getElValue("txt_RoomDetail_DayDetail_AgencyPrice_"+props.idCode+"_"+props.roomid+"_"+props.dayindex),
                };
            }

            console.log("confirmValues DayDetail "+JSON.stringify(rv.result));

            return rv;
        }

        return {
            setContentInner,
            confirmValues,
        };
    },
});
