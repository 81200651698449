
import {computed, defineComponent, ref} from "vue";
import AutoComplete from "@/components/appcomps/forms/AutoComplete.vue"
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "Hotel-Room-Concept",
    components: {
        AutoComplete,
    },
    methods: {
        getResults() {
            let rv = this.confirmValues();
            return rv;
        },
    },
    props: {
        ProcessType: String,
    },
    setup(props) {

        const refAutoComplete_Hotels =  ref(null);

        let selItemHotel = ref ({});
        let theHotel = ref ({});
        let ccvHotelRoomId = ref(0);
        let ccvHotelConceptId = ref(0);

        let ccvHotelInfo = ref({});

        function setContent(prmHotelInfo) {
            //console.log("HotelRoomConcept setContent START "+JSON.stringify(prmHotelInfo));
            ccvHotelInfo.value = prmHotelInfo;
            this.refAutoComplete_Hotels.setInput(prmHotelInfo.RemarksVC2);

            selectItemAutoCompHotels({id:Number((ccvHotelInfo.value as any).RemarksINT1), name:prmHotelInfo.RemarksVC2});

        }

        //### AutoComplete Hotels
        function keyupAutoCompHotels(prmInputValue) {
            console.log("keyupAutoCompHotels prmInputValue: "+prmInputValue);
            selItemHotel.value = {};
            if (prmInputValue.length == 3) {
                store.dispatch(Actions.HOTEL_LIST_ALL, { 'Title': prmInputValue});
            }
        };

        function selectItemAutoCompHotels(prmItem) {
            console.log("selectItemAutoCompHotels prmItem: "+prmItem.id+" "+prmItem.name);
            selItemHotel.value = prmItem;

            libTools.method.setElValue("sel_HotelReservation_"+props.ProcessType+"_HotelId", prmItem.id);

            setList_HotelRooms([]);
            setList_HotelConcepts([]);

            store.dispatch(Actions.HOTEL_ROOMS_LIST, {HotelID: prmItem.id, callFrom:"CF_HOTEL_ROOMS_LIST_ReservationAdd",});
            store.dispatch(Actions.HOTEL_CONCEPTS_LIST, {HotelID: prmItem.id, is_active: "active", callFrom:"CF_HOTEL_CONCEPTS_LIST_ReservationAdd",});
        };

        function itemProjectionHotels (prmItem) {
            //console.log("itemProjectionHotels id: "+prmItem.id+" "+prmItem.name);
            return prmItem.name;
        };

        const dataHotelList = computed(() => {
            let dataComputed = store.getters.hotelList;
            if (dataComputed!==undefined) {
                return dataComputed;
            }
            return {data:[]};
        });

        const lstAutoCompleteItemsHotels = computed(() => {
            let arrTmp: unknown[] = [];
            if (dataHotelList.value!==undefined && dataHotelList.value.data !== undefined) {
                for (var each of dataHotelList.value.data) {
                    arrTmp.push({"id": each.ID, "name": each.Title});
                }
            }
            return arrTmp;
        });

        //### Hotel Rooms
        let arrHotelRooms = ref({});

        const dataHotelRoomsList = computed(() => {
            let dataComputed =store.getters.hotelRoomsList2;
            if (dataComputed!=undefined && dataComputed.data!==undefined) {
                setList_HotelRooms(dataComputed.data);
            }
            return dataComputed;
        });

        function setList_HotelRooms(prmData) {
            arrHotelRooms.value = prmData;
            if (ccvHotelInfo.value!==null) {
                ccvHotelRoomId.value = Number((ccvHotelInfo.value as any).RemarksINT2);
            }
        }

        //### Hotel Concept
        let arrHotelConcepts = ref({});

        const dataHotelConceptsList = computed(() => {
            let dataComputed =store.getters.hotelConceptsList2;
            if (dataComputed!=undefined && dataComputed.data!==undefined) {
                setList_HotelConcepts(dataComputed.data);
            }
            return dataComputed;
        });

        function setList_HotelConcepts(prmData) {
            arrHotelConcepts.value = prmData;
            if (ccvHotelInfo.value!==null) {
                ccvHotelConceptId.value = Number((ccvHotelInfo.value as any).RemarksINT3);
            }
        }

        //##Confirm
        function confirmValues() {
            let rv = {isok: true, result: {},};

            if ((selItemHotel.value as any).id === undefined) {
                libTools.method.swalError("Tamam", "Otel seçilmemiş");
                rv.isok = false;
            }

            console.log("HotelRoomConcept.vue confirmValues "+"sel_HotelReservation_"+props.ProcessType+"_HotelRoomId");

            if (rv.isok && libTools.method.getElValue("sel_HotelReservation_"+props.ProcessType+"_HotelRoomId")==="") {
                libTools.method.swalError("Tamam", "Oda seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok && libTools.method.getElValue("sel_HotelReservation_"+props.ProcessType+"_HotelConceptId")==="") {
                libTools.method.swalError("Tamam", "Oda Konsepti seçilmemiş");
                rv.isok = false;
            }

            if (rv.isok) {
                rv.result= {
                    HotelId: (selItemHotel.value as any).id,
                    HotelTitle: (selItemHotel.value as any).name,
                    HotelRoomId: libTools.method.getElValue("sel_HotelReservation_"+props.ProcessType+"_HotelRoomId"),
                    HotelRoomTitle: libTools.method.getElSelectedText("sel_HotelReservation_"+props.ProcessType+"_HotelRoomId"),
                    HotelConceptId: libTools.method.getElValue("sel_HotelReservation_"+props.ProcessType+"_HotelConceptId"),
                    HotelConceptTitle: libTools.method.getElSelectedText("sel_HotelReservation_"+props.ProcessType+"_HotelConceptId"),
                };
            }

            return rv;
        }

        return {
            refAutoComplete_Hotels,
            setContent,
            ccvHotelRoomId, ccvHotelConceptId,
            keyupAutoCompHotels, selectItemAutoCompHotels, itemProjectionHotels, lstAutoCompleteItemsHotels,
            dataHotelRoomsList, arrHotelRooms,
            dataHotelConceptsList, arrHotelConcepts,
            confirmValues,
        };
    },
});
